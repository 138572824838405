import React from 'react';
import Image from 'next/image';
import { GiHamburgerMenu } from 'react-icons/gi';

import WrapperLink from 'components/WrapperLink';

import {
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Flex,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react';
import { HoverDropdown } from 'components/common/HoverDropdown';
import { ROUTES } from 'constants/routes';

import styles from './Navbar.module.css';

type TLink = {
  title: string;
  href: string;
};

function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const extraLinks = [
    { title: 'Get Started', href: '/student/signup' },
    { title: 'Log In', href: '/student/login' },
  ];

  const signupSubItems = [
    { title: 'Student', url: ROUTES.studentSignup },
    { title: 'Tutor', url: ROUTES.tutorSignup },
  ];

  const loginSubItems = [
    { title: 'Student', url: ROUTES.studentLogin },
    { title: 'Tutor', url: ROUTES.tutorLogin },
  ];

  return (
    <div role="banner" className={`${styles.header} ${styles['w-nav']}`}>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody minH={'full'}>
            <Flex
              m={'auto'}
              h={'full'}
              gap="1.3em"
              flexDir={'column'}
              pt="5em"
              alignItems={'flex-start'}
            >
              <NavLinks extraLinks={extraLinks} />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <div
        className={`${styles['container-default']} ${styles['header-container']} ${styles['w-container']}`}
      >
        <div className={styles['header-wrapper']}>
          <div
            className={`${styles['split-content']} ${styles['header-right']}`}
          >
            <WrapperLink
              href="/"
              className={`${styles.brand} ${styles['w-nav-brand']} ${styles['w--current']}`}
            >
              <Image
                width={200}
                height={40}
                src={'/navbar/logo.png'}
                alt="logo"
                className={styles['header-logo']}
              />
            </WrapperLink>
          </div>

          <NavLinks />
          {isMobile ? (
            <IconButton
              aria-label="menu"
              alignContent={'center'}
              // eslint-disable-next-line react/no-children-prop
              children={<GiHamburgerMenu />}
              display={['flex', 'flex', 'none']}
              onClick={onOpen}
            />
          ) : (
            <div className={styles.buttonsContainer}>
              <HoverDropdown
                title="Get Started"
                items={signupSubItems}
                extraTitleClasses={styles.primaryButton}
              />
              <HoverDropdown title="Login" items={loginSubItems} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const NavLinks = ({ extraLinks = [] }: { extraLinks?: TLink[] }) => {
  const navLinks = [
    { title: 'Home', href: '/' },
    { title: 'About', href: '/about' },
    { title: "FAQ's", href: '/faqs' },
    { title: 'Blog', href: '/blog' },
    { title: 'Free Resources', href: '/free-resources' },
    ...extraLinks,
  ];

  return (
    <nav
      role="navigation"
      className={`${styles['nav-menu']} ${styles['w-nav-menu']}`}
      data-collapse="medium"
    >
      <ul role="list" className={styles['header-navigation']}>
        {navLinks.map((link, index) => (
          <li key={index} className={styles['nav-item-wrapper']}>
            {link && (
              <WrapperLink
                href={link.href || ''}
                aria-current="page"
                className={`${styles['nav-link']} ${styles['w--current']}`}
              >
                {link.title}
              </WrapperLink>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;

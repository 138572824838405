import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';

import styles from './HoverDropdown.module.css';

type THoverDropdownProps = {
  title: string;
  items: { title: string; url: string }[];
  extraTitleClasses?: string | string[];
};
export const HoverDropdown = ({
  title,
  items,
  extraTitleClasses,
}: THoverDropdownProps) => {
  return (
    <div className={styles.dropdown}>
      <div className={styles.dropdownContainer}>
        <div className={clsx(styles.titleContainer, extraTitleClasses)}>
          {title}
          <Image
            height={24}
            width={24}
            src="/icons/dropdown-arrow.svg"
            alt="dropdown arrow"
            style={{ color: 'white', fill: 'red' }}
          />
        </div>

        <div className={styles.dropdownContent}>
          {items.map(({ title, url }, idx) => (
            <div
              className={styles.dropdownContent__item}
              key={`${title}-${idx}`}
            >
              <Link href={url}>{title}</Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HoverDropdown;
